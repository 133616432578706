import { Menu, Search } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import MobileMenu from './MobileMenu'

const Header = () => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false);
    const [textColor, setTextColor] = useState('white')


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if(window.location.pathname != '/'){
        setTextColor('black')

    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <MobileMenu open={openDrawer} setOpen={setOpenDrawer}/>
    <div className={`flex items-center justify-between z-50 fixed w-full  lg:px-[134px] pt-[22px] lg:pt-[24px]  ${isScrolled ? 'bg-white shadow-lg text-black pb-2 transition-all duration-500' : 'text-'+textColor+' transition-all duration-500'}`} >

        <div className='mr-[210px] flex items-center'>
            <img src='/logo_main.png'
             className='w-[29px] lg:w-[56px] lg:h-[52px] h-[27px]' alt='logo'/>
             <span className='font-[700] lg:text-[20px] text-[10px] ml-[12px]' >Teckity</span>
        </div>

        <div className='hidden lg:flex items-center  w-full justify-between'>
            <div className={window.location.pathname === '/' ?'cursor-pointer  underline decoration-[#18bef1] decoration-[2px] underline-offset-4' : 'cursor-pointer custom-classx' }onClick={()=> {window.location.pathname = '/'}} >Home</div>
            <div className={window.location.pathname === '/contact-us' ?'cursor-pointer  underline decoration-[#18bef1] decoration-[2px] underline-offset-4' : 'cursor-pointer custom-classx' } onClick={()=> {window.location.pathname = '/contact-us'}} >Contact</div>
            <div className={window.location.pathname === '/blog' ?'cursor-pointer  underline decoration-[#18bef1] decoration-[2px] underline-offset-4' : 'cursor-pointer custom-classx' } onClick={()=> {window.location.pathname = '/blog'}}>Blog</div>
            <div className={window.location.pathname === '/team' ?'cursor-pointer  underline decoration-[#18bef1] decoration-[2px] underline-offset-4' : 'cursor-pointer custom-classx' } onClick={()=> {window.location.pathname = '/team'}}>Team</div>

          <img src='/icon12.png' className='w-[24px] h-[24px]' />
            <div>
                <Button sx={{
                    textTransform:'none',
                    borderRadius:'32px',
                    bgcolor:'#18bef1',
                    color:'white',
                    fontWeight:'semibold',
                    fontSize: { xs: "13px", lg: "16px" },
                    px:'32px',
                    py:'16px',
                    display:'swap',
                    "&:hover": {
                      color: "black",
                    },

                }}
                
                onClick={()=> {
                  if(document.querySelector('#contactform')){
                    document.querySelector('#contactform').scrollIntoView();
                  }else{
                    window.location.href = 'https://teckity.com/#contactform'
                    
                  }
                }}
                >Contact Us</Button>
            </div>
        </div>

        <div className='lg:hidden mr-3' >
        <div className="relative">
      <button
        className="text-white"
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        {openDrawer ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
              color={isScrolled   || window.location.pathname !== '/' ? 'black' : 'white'}
            />
          </svg>
        ) : (
            <Menu sx={isScrolled || window.location.pathname !== '/'? { color: 'black' } : {color:'white'}} onClick={()=> setOpenDrawer(true)}/>
        )}
      </button>
    </div>
        </div>

    </div>
    </>
  )
}

export default Header