import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import Header from './layout/Header';
import Main from './Main';


function App() {
  return (
    <div className="">
     <Main/>
    </div>
  );
}

export default App

