import { Circle } from "@mui/icons-material";
import { Avatar, Button, InputBase } from "@mui/material";
import React, { useState } from "react";
import FloatingIcons from "../components/FloatingIcons";
import Snack from "../components/Snack";
import TestimonialCard from "../components/TestimonialCard";

const HomeScreen = () => {
  const [open, setOpen] = useState()
  const [msg, setMsg] = useState()
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    contact: "",
    email: "",
    about_org: "",
    project_budget: "",
    project_description: "",
    project_timeline: "",
    business_type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  
    fetch("https://yelp-chatgpt-api-8d39b1455bcb.herokuapp.com/api/teckity/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setOpen(true)
        setMsg("Message sent, you will get a response soon.")
        setFormData({
          first_name: "",
          last_name: "",
          contact: "",
          email: "",
          about_org: "",
          project_budget: "",
          project_description: "",
          project_timeline: "",
          business_type: "",
        });
        console.log("Success:", data);
      })
      .catch((error) => {
       
      
        setOpen(true)
        setMsg("Error msg did not send")
        console.error("Error:", error);
      });
  };

  
  return (
    <>
    <Snack open={open} setOpen={setOpen} msg={msg} />
      <FloatingIcons />
      <div className="relative ">
        <img
          src="/bgcc.png"
          className=" lg:h-[60vw] h-[80vw] w-full top-0 hidden lg:block "
        />
        <img src="/bgcc2.png" className="  h-[639px] w-full top-0 lg:hidden " />

        <div className="absolute lg:top-[190px] top-[93px] lg:px-[134px]   px-2 text-white">
          <div className="lg:text-[61px] text-center lg:text-left text-[39px] font-[700] mb-[32px] lg:w-[861px]">
            Digital Solutions Tailored to Your Ambitions
          </div>

          <div className="lg:text-left text-[16px] text-center  lg:text-[25px] font-[400] lg:mt-0 mt-[16px]  lg:w-[861px] mb-[16px] lg:mb-[22px]">
            Our team of skilled designers and developers is committed to
            bringing your vision to life. Together, we'll create digital
            experiences that leave a lasting impression
          </div>

          <div className="lg:flex   mb-[82px] lg:mb-0  ">
            <div className=" lg:hidden lg:mb-2 mb-[16px] flex justify-center">
              <Button
                sx={{
                  textTransform: "none",
                  borderRadius: "32px",
                  bgcolor: "#18bef1",
                  color: "white",
                  fontWeight: "semibold",
                  fontSize: { xs: "16px", lg: "16px" },
                  px: "32px",
                  py: "16px",
                  fontFamily: "poppins",
                }}
                onClick={() => {
                  document.querySelector("#services").scrollIntoView();
                }}
              >
                Services and Products
              </Button>
            </div>

            <div className=" lg:mr-4  flex justify-center">
              <Button
                sx={{
                  textTransform: "none",
                  borderRadius: "32px",
                  bgcolor: "transparent",
                  border: "1px solid white",
                  color: "white",
                  fontWeight: "semibold",
                  fontSize: { xs: "16px", lg: "16px" },
                  px: "32px",
                  py: "16px",
                  fontFamily: "poppins",
                }}
                onClick={() => {
                  document.querySelector("#contactform").scrollIntoView();
                }}
              >
                Contact Us
              </Button>
            </div>

            <div className="hidden lg:block ">
              <Button
                sx={{
                  textTransform: "none",
                  borderRadius: "32px",
                  bgcolor: "#18bef1",
                  color: "white",
                  fontWeight: "semibold",
                  fontSize: { xs: "16px", lg: "16px" },
                  px: "32px",
                  py: "16px",
                  fontFamily: "poppins",
                }}
                onClick={() => {
                  document.querySelector("#services").scrollIntoView();
                }}
              >
                Checkout our services and Products
              </Button>
            </div>
          </div>
        </div>

        <div className=" hidden lg:grid grid-cols-3 gap-7 relative lg:bottom-[5vw] bottom-[10vw]  text-white text-[25px] font-[700] lg:text-[17px]  ">
          <div className="font-bold col-span-1 text-center">
            <div>Trusted by over 70+</div> Brands
          </div>
          <div className="font-bold col-span-1 text-center">
            <div>Over 100 Products/Solutions </div> built
          </div>
          <div className="font-bold col-span-1 text-center">
            <div>5 years of </div> experience
          </div>
        </div>
      </div>

      <div className="lg:flex items-center justify-between   mt-[30px]   lg:px-[134px] px-[20px]  ">
        <div className="flex justify-center col-span-1">
          <img
            src="/img.png"
            className=" lg:w-[512px] lg:h-[412px] h-[248px] hidden  lg:block img-responsive"
            alt="img"
          />

          <img
            src="/icons222.png"
            className="w-[335px] lg:w-[512px] lg:h-[512px] h-[248px] lg:hidden"
            alt="img"
          />
        </div>

        <div className="col-span-1 mt-[27px] lg:mt-0">
          <div className="lg:text-[25px] text-[16px] font-[700] text-[#1187AB]">
            What we do.
          </div>

          <div className="lg:w-[616px]  mt-[3px] mb-[24px] lg:text-[49px] text-[31px] font-[700] ">
            Crafting Digital Excellence, One Solution at a Time
          </div>

          <div className="lg:text-[20px] text-[16 px] font-[400] lg:w-[616px] mb-[44px]">
            At Teckity, we're passionate about turning ideas into reality. With
            a talented team of designers and developers, we've been crafting
            digital solutions that empower businesses to thrive in the digital
            age.
            <br />
            <br />
            <span className="text-[#18bef1] font-[500]">
              Our mission is simple
            </span>
            – we're here to help you succeed. Whether it's a cutting-edge mobile
            app, a sleek web design, or a powerful e-commerce platform, we've
            got the expertise to bring your vision to life.
          </div>

          <div>
            <Button
              sx={{
                textTransform: "none",
                borderRadius: "32px",
                bgcolor: "#18bef1",
                color: "white",
                fontWeight: "semibold",
                fontSize: { xs: "16px", lg: "16px" },
                px: "32px",
                py: "16px",
                "&:hover": {
                  color: "black",
                },
                width: { lg: "182px", xs: "100%" },
              }}
              onClick={() => {
                document.querySelector("#contactform").scrollIntoView();
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>

      <div className="lg:mt-[225px] mt-[51px] " id="services">
        <div className="text-center font-[700] lg:text-[49px] text-[31px] mb-[16px] lg:mb-[44px] ">
          Our Services/Solutions
        </div>
        <div className="lg:text-center text-left px-[22px] lg:px-0 font-[300] lg:text-[25px] text-[20px] lg:mb-[40px] mb-[32px]">
          Transforming Ideas into Digital Realities at Teckity
        </div>

        <div className="grid lg:grid-cols-3 gap-4 p-3 lg:px-[200px]">
          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE] lg:py-[52.2px] py-[20px]  ">
            <div className="lg:text-[20px] text-[16px] font-[300]">
              Ecommerce Web Development
            </div>
          </div>

          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE]  lg:py-[52.2px] py-[20px] ">
            <div className="lg:text-[20px] text-[16px] font-[300]">
              Mobile and web App Development
            </div>
          </div>

          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE]  lg:py-[52.2px] py-[20px] ">
            <div className="lg:text-[20px] text-[16px] font-[300]">
             Websites
            </div>
          </div>

          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE]  lg:py-[52.2px] py-[20px] ">
            <div className="lg:text-[20px] text-[16px] font-[300]">
              Graphic Design
            </div>
          </div>

          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE]  lg:py-[52.2px] py-[20px] ">
            <div className="lg:text-[20px] text-[16px] font-[300]">
              Branding
            </div>
          </div>

          <div className="col-span-1 flex justify-center border-[1px] border-[#46CBF4] bg-[#E8F9FE]  lg:py-[52.2px] py-[20px]">
            <div className="lg:text-[20px] text-[16px] font-[300]">
              Project management
            </div>
          </div>
        </div>
      </div>

      <div className="lg:mt-[170px] mt-[17px] px-[17px] lg:px-0 ">
        <div className="lg:text-center font-[700] lg:text-[49px] text-[31px] lg:mb-[44px] mb-[16px]">
          Checkout our best works
        </div>
        <div className="lg:text-center font-[300] lg:text-[25px] text-[20px]   lg:mb-12 mb-[32px] lg:px-[134px] ">
          The portfolio showcases successful projects delivered on time and
          within budget, reflecting Teckity's commitment to excellence.
        </div>

        <div
          className="grid lg:grid-cols-3 gap-4 lg:px-[200px]  "
         
        >
          <div className="col-span-1 border-[1px] bg-[#F1F1F5] relative overflow-hidden group rounded-md cursor-pointer h-[370px]"
          //  onClick={() => {
          //   window.open("https://app.bnbbuyer.com", "_blank");
          // }}
          >
            <div className="absolute inset-0 flex flex-col items-center justify-end  transition-all opacity-100 group-hover:z-30 hover:opacity-100  bg-opacity-90  text-black ">
              {/* <div className="bg-[#F1F1F5] px-6 py-6">
                <div className="lg:text-[20px] text-[18px] lg:mb-2 mb-[16px] lg:font-[300] font-[400] lg:text-center text-left">
                  Bnbbuyer Platform
                </div>
                <div className="lg:text-[16px] text-[13px] font-[500] lg:font-[300] leading-7 text-left">
                  We transformed a local boutique's dream into a global reality
                  with a visually stunning and user-friendly e-commerce
                  platform. The result? A 300% increase in online sales within
                  the first quarter
                </div>
              </div> */}
            </div>
            <div className="relative w-full lg:h-[400px] h-[340px]  mb-[16px] overflow-hidden">
              <iframe
              scrolling="no"
                src="https://bnbbuyer.com"
                className="w-full h-full object-cover transition-all duration-300 transform group-hover:scale-110"
                title="Embedded Snapshot"
              />
            </div>
          </div>

          <div className="col-span-1 border-[1px] bg-[#F1F1F5] relative overflow-hidden group rounded-md cursor-pointer h-[370px]"
          
          // onClick={() => {
          //   window.open("https://bochnewng.com", "_blank");
          // }}
          >
            <div className="absolute inset-0 flex flex-col items-center justify-end  transition-all opacity-100 group-hover:z-30 hover:opacity-100  bg-opacity-90  text-black ">
              {/* <div className="bg-[#F1F1F5] px-6 py-6">
                <div className="lg:text-[20px] text-[18px] lg:mb-2 mb-[16px] lg:font-[300] font-[400] lg:text-center text-left">
                  Bochnewsng
                </div>
                <div className="lg:text-[16px] text-[13px] font-[500] lg:font-[300] leading-7 text-left">
                  We transformed a local boutique's dream into a global reality
                  with a visually stunning and user-friendly e-commerce
                  platform. The result? A 300% increase in online sales within
                  the first quarter
                </div>
              </div> */}
            </div>
            <div className="relative w-full lg:h-[400px] h-[340px]  mb-[16px] overflow-hidden">
              <iframe
               scrolling="no"
                src="https://bochnewsng.com"
                className="w-full h-full object-cover transition-all duration-300 transform group-hover:scale-110"
                title="Embedded Snapshot"
              />
            </div>
          </div>

          <div className="col-span-1 border-[1px] bg-[#F1F1F5] relative overflow-hidden group rounded-md cursor-pointer h-[370px]">
            {/* <div className="absolute inset-0 flex flex-col items-center justify-end  transition-all opacity-100 group-hover:z-30 hover:opacity-100  bg-opacity-90  text-black ">
              <div className="bg-[#F1F1F5] px-6 py-6">
                <div className="lg:text-[20px] text-[18px] lg:mb-2 mb-[16px] lg:font-[300] font-[400] lg:text-center text-left">
                 Homelife media
                </div>
                <div className="lg:text-[16px] text-[13px] font-[500] lg:font-[300] leading-7 text-left">
                  We transformed a local boutique's dream into a global reality
                  with a visually stunning and user-friendly e-commerce
                  platform. The result? A 300% increase in online sales within
                  the first quarter
                </div>
              </div>
            </div> */}
            <div className="relative w-full lg:h-[400px] h-[340px]  mb-[16px] overflow-hidden">
              <iframe
               scrolling="no"
                src="https://homelifemedia.com"
                className="w-full h-full object-cover transition-all duration-300 transform group-hover:scale-110"
                title="Embedded Snapshot"
                data-cookieconsent="marketing"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative lg:mt-[200px] mt-[51px] lg:px-[134px] lg:py-[129px] px-[16px]   lg:bg-gradient-to-r from-[rgba(232,249,254,1)] to-[rgba(255,255,255,0)] lg:grid grid-cols-3 gap-[72.5px] lg:mb-[80px] mb-[40px]">
        <div className="col-span-1 lg:py-[90px]">
          <div className="lg:text-[49px] text-[31px] font-[700] leading-[73.px] lg:mb-[40px] mb-[16px]  ">
            What our Clients are saying
          </div>
          <div className="lg:text-[25px] text-[20px] font-[400] ">
            Hear from Those Who've Partnered with Teckity
          </div>
        </div>

        <div className="col-span-2 lg:grid grid-cols-2 gap-x-[59px] gap-y-[64px] relative lg:mt-0 mt-[32px]">
          <img
            src="/quote.png"
            className="hidden lg:block absolute top-[-70px] left-[-60px] w-[112px] h-[112px] "
          />
          <TestimonialCard comment = "Teckity’s expertise in web development and their commitment to our vision has made them a valuable partner." name ="Sarah Thompson" company={"BrightStart Tech"}/>
          <TestimonialCard comment="Teckity's branding magic transformed All Things Glam! Our vision came to life with their expert touch. Highly recommend for a standout brand identity." name="Arthur V." company={"All Things Glam"} />
          <TestimonialCard comment = "Teckity's website wizardry reshaped Trail's online presence. Seamless design and user experience. A go-to for digital transformation!" name ="Teddy A." company={"Trail"}/>
            <TestimonialCard comment = "Slink's app got a digital makeover by Teckity! Their innovation and creativity took us to new heights. Choose Teckity for app excellence." name ="Jonathan Pike" company={"Slink"}/>
            
        </div>
      </div>

      <div className="relative mb-[41px] lg:mb-[71px]"  id="contactform" >
        {/* <img
          src="/geo.png"
          className=" lg:h-[50vw] h-[887px] w-full top-0 hidden lg:block  "
        />
        <img
          src="/mordernbg.png"
          className=" lg:h-[50vw] h-[950px] w-full top-0 lg:hidden "
        /> */}

        <div className="    text-black ">
          <div className=" flex justify-center ">
            <div className="grid lg:grid-cols-3   lg:w-[70%] w-full lg:bg-white bg-transparent  shadow-lg  ">
              <div className="lg:col-span-1 flex flex-col lg:py-[60px] lg:space-y-8 space-y-[16px] lg:px-[30px] px-[16px]">
                <div className="flex-1"></div>
                <div className="text-[40px] font-[700]">
                  Let's Start a Conversation
                </div>
                <div className="text-[20px] font-[400]">
                  Reach out to us. We would like to hear from you.
                </div>
                <div className="flex-1"></div>
              </div>
              <div className="lg:col-span-2 bg-[#F1F1F5] lg:px-[20px] px-4 py-[30px] space-y-5 mt-[32px] lg:mt-0">
                <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                  <InputBase
                    placeholder="First name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                  <InputBase
                    placeholder="Last name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                  <InputBase
                    placeholder="Contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                  <InputBase
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div>
                  <InputBase
                    placeholder="Tell us about your organization/ brand"
                    name="about_org"
                    value={formData.about_org}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div>
                  <InputBase
                    placeholder="Business Type"
                    name="business_type"
                    value={formData.business_type}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div>
                  <InputBase
                    placeholder="Project Description"
                    name="project_description"
                    value={formData.project_description}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                  <InputBase
                    placeholder="Project Timeline"
                    name="project_timeline"
                    value={formData.project_timeline}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                  <InputBase
                    placeholder="Project Budget"
                    name="project_budget"
                    value={formData.project_budget}
                    onChange={handleChange}
                    sx={{
                      bgcolor: "white",
                      px: "16px",
                      py: "16px",
                      width: "100%",
                      fontSize: { lg: "20", sm: "13px" },
                      weight: "400",
                      fontStyle: "poppings",
                    }}
                  />
                </div>

                <div>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "32px",
                      bgcolor: "#18bef1",
                      color: "white",
                      fontWeight: "semibold",
                      fontSize: { xs: "16px", lg: "16px" },
                      px: "42px",
                      py: "10px",
                      width: "fit",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
