import { Slide } from "@mui/material";
import React, { useEffect, useState } from "react";

const ProjectScreen = () => {
  const [projectType, setProjectType] = useState(1);
  const [showCode, setShowCode] = useState(true);
  const handleButtonClick = () => {
    setShowCode(true);
  }
  return (
    <>
      <div className="relative mb-[300px] lg:top-[150px] top-[93px] w-full lg:px-[113px] px-[19.5px]">
        <div className=" lg:text-center font-[700] lg:text-[49px] text-[31px] lg:mb-[44px] mb-[16px]">
          Our Packages
        </div>
        <div className=" lg:text-center font-[300] lg:text-[25px] text-[16px]">
          At Teckity, we don't just build products; we craft digital journeys.
          Let's embark on this journey together and transform your ideas into
          reality."
        </div>

        <div className="my-[64px] flex items-center lg:space-x-[66px] lg:text-[20px] text-[15px] font-[400] space-x-[20px]">
          <div
            className={
              projectType === 1
                ? "font-[400] text-[20px] cursor-pointer text-[#0D6985]"
                : "font-[400] text-[20px] cursor-pointer"
            }
            onClick={() => {
              setProjectType(1);
              handleButtonClick()
            }}
          >
            Mobile App
          </div>
          <div
            className={
              projectType === 2
                ? "font-[400] text-[20px] cursor-pointer text-[#0D6985]"
                : "font-[400] text-[20px] cursor-pointer"
            }
            onClick={() => {
              setProjectType(2);
            }}
          >
            Website
          </div>
        </div>

        {showCode && (
          <div >
            {projectType === 1 && <ProjectTypeMobileApp />}
            {projectType === 2 && <ProjectTypeWebsite />}
          </div>
        )}

        
      </div>
    </>
  );
};

export default ProjectScreen;

const ProjectTypeMobileApp = () => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <Slide direction="up" in={isVisible} timeout={500}>
    <div>
      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>
    </div>
    </Slide>
  );
};

const ProjectTypeWebsite = () => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <Slide direction="up" in={isVisible} timeout={500}>
    <div>
      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-7 border-[#F1F1F5] hover:border-[#B7EBFB] cursor-pointer border-[2px] rounded-[16px] lg:my-[64px] my-[36px]">
        <div className="lg:col-span-3 lg:p-[32px] p-[16px] bg-white rounded-[16px] ">
          <div className="font-[700] lg:text-[49px] text-[31px] text-[#18bef1]  ">
            BRONZE <br />
            <span className="font-[700] lg:text-[49px] text-[31px] text-black ">
              PACKAGE{" "}
            </span>
          </div>
          <div className="  font-[300] lg:text-[25px]  text-[16px] text-left">
            Reach out to us. We would like to hear from you.
          </div>
        </div>

        <div class="lg:col-span-4 lg:p-[32px] p-[16px] bg-[#F1F1F5]  flex flex-wrap space-y-2 lg:space-y-0  rounded-bl-[14px]   lg:rounded-bl-[0px]       lg:rounded-tr-[16px]  rounded-br-[14px] lg:rounded-br-[16px] ">
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px]  h-fit text-[13px] lg:text-[20px]">
            UI UX Design
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Frontend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Backend Development
          </div>
          <div class="text-gray-700 bg-white rounded-[16px] lg:w-[270px] w-fit px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px]">
            Branding
          </div>
          <div className="text-gray-700 bg-white rounded-[16px] lg:w-auto lg:max-w-[270px] px-[20px] py-[16px] lg:mr-[20px] mr-[10px] h-fit text-[13px] lg:text-[20px] ">
            Maintenance for 4 months
          </div>
        </div>
      </div>
    </div>
    </Slide>
  );
};
