import React from "react";

const TeamScreen = () => {
  return (
    <>
      <div className="relative mb-[300px] lg:top-[150px] top-[93px] w-full lg:px-[113px] px-[19.5px]">
        <div className=" lg:text-center font-[700] lg:text-[49px] text-[31px] lg:mb-[44px] mb-[16px]">
          Meet the Team
        </div>
        <div className=" lg:text-center font-[300] lg:text-[25px] text-[16px] lg:mb-[44px] mb-[16px]">
          Meet the Experts Behind Your Success
        </div>

    <div className="flex justify-around">
    <div className="lg:grid grid-cols-3  space-y-[16px] gap-[40px]  lg:space-y-0">
          <div className="flex flex-col items-center">
            {" "}
            {/* Center content */}
            <img
              src="/kachi1.png"
              className="w-full h-auto lg:w-[284px] lg:h-[282px]"
              alt="Kachi's Portrait"
            />
            <div className="lg:text-[20px]  text-[15px] mt-[17.5px] text-center font-[400]">
              Edmondson Kachi
            </div>
            <div className="lg:text-[13px] text-[10px] text-center  mb-[25px] font-[400]">
              Founder and UI UX Designer
            </div>
          </div>
          <div className="flex flex-col items-center">
            {" "}
            {/* Center content */}
            <img
              src="/francis.jpeg"
              className="w-full h-auto lg:w-[284px] lg:h-[282px]"
              alt="Francis' Portrait"
            />
            <div className="lg:text-[20px] text-[15px] mt-[17.5px] text-center font-[400]">
             Justice Francis
            </div>
            <div className="lg:text-[13px] text-[10px] text-center mb-[25px]  font-[400]">
              Co-Founder and Software Developer
            </div>
          </div>
          <div className="flex flex-col items-center">
            {" "}
            {/* Center content */}
            <img
              src="/barth1.png"
              className="w-full h-auto lg:w-[284px] lg:h-[282px]"
              alt="Barth's Portrait"
            />
            <div className="lg:text-[20px] text-[15px] mt-[17.5px] text-center font-[400]">
              Batholomew Onogwu
            </div>
            <div className="lg:text-[13px] text-[10px] text-center mb-[25px]  font-[400]">
              Software Developer
            </div>
          </div>
        
          
        </div>
    </div>

      </div>
        
    </>
  );
};

export default TeamScreen;
