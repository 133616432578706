import { Avatar } from "@mui/material";
import React, { useEffect, useRef } from "react";

const TestimonialCard = ({name,comment, company}) => {

 

  return (
    <div
      className="bg-white shadow-md px-[16px] py-[32px] h-fit rounded-[16px] mb-[16px] lg:mb-0"
    >
      <div className="mb-[16px] lg:text-[16px] text-[13px] font-[400] leading-[27px]">
      "{comment}"
      </div>
      <div className="flex items-center space-x-[26px] ">
        <Avatar src="/person222.png" sx={{ width: "56px", height: "56px" }} />

        <div>
          <div className="text-[16px] font-[400]">{name}</div>
          <div className="text-[13px] font-[500]">{company}</div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
