import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import Header from "./layout/Header";
import ProjectScreen from "./screens/ProjectScreen";
import TeamScreen from "./screens/TeamScreen";
import BlogScreen from "./screens/BlogScreen";
import ContactUsScreen from "./screens/ContactUsScreen";

function Main() {
  return (
    <>
      <Header />
      <div class="flex flex-col min-h-screen">
        <div className="flex-1">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/project" element={<ProjectScreen />} />
              <Route path="/team" element={<TeamScreen />} />
              <Route path="/blog" element={<BlogScreen />} />
              <Route path="/contact-us" element={<ContactUsScreen />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>

      <div className="bg-[#191B50]   lg:px-32 px-4 lg:py-8  lg:pt-[60px]  text-[14px] lg:text-[16px]  py-8 text-white text-center footer">
        Teckity 2023 © All Rights Reserved
      </div>
    </>
  );
}

export default Main;
