import {
  Chat,
  ChatBubbleOutline,
  Favorite,
  FavoriteBorder,
  Repeat,
} from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

const BlogScreen = () => {
  const relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  return (
    <>
      <div className="relative mb-[300px] lg:top-[150px] top-[93px] w-full lg:px-[113px] px-[19.5px]">
        <div className=" lg:text-center font-[700] lg:text-[49px] text-[31px] lg:mb-[44px] mb-[16px]">
          Blog Posts
        </div>
        <div className=" lg:text-center font-[300] lg:text-[25px] text-[16px] lg:mb-[44px] mb-[16px]">
          At Teckity, we don't just build products; we craft digital journeys.
          Let's embark on this journey together and transform your ideas into
          reality."
        </div>

        <div class="grid grid-cols-3 gap-x-[39px] gap-y-[20px]">
         
          <div class="lg:col-span-1 col-span-4 bg-white shadow-md cursor-pointer overflow-x-hidden group"
          onClick={()=>{
            window.open('https://www.linkedin.com/posts/teckity_techsolutions-founders-solutions-activity-7129505115346001920-rUOS?utm_source=share&utm_medium=member_desktop','_blank')
          }}
          >
          <img src="/code.png" className="w-full h-[240px] lg:h-[186px] transform group-hover:scale-110 transition-transform" />

            <div className="flex">
              <div className="flex-1"></div>

              {/* <div className="flex items-center space-x-2  justify-end px-3 relative bottom-9 bg-white shadow-md w-fit mx-2 rounded-full">
                <div className="flex items-center ">
                  <FavoriteBorder
                    style={{ color: "#0D6985", fontSize: "15px" }}
                  />
                  <span className="text-gray-600 text-[13px]">12</span>
                </div>
                <div className="flex items-center">
                  <ChatBubbleOutline
                    style={{ color: "#0D6985", fontSize: "15px" }}
                  />
                  <span className="text-gray-600 text-[13px]">13</span>
                </div>
                <div className="flex items-center">
                  <Repeat style={{ color: "#0D6985", fontSize: "15px" }} />
                  <span className="text-gray-600 text-[13px]">10</span>
                </div>
              </div> */}
            </div>

            <div className="px-[20px] py-[15px]">
              <div className=" lg:text-left font-[400] lg:text-[20px]  text-[16px] lg:mb-[24px] mb-[10px]">
              The Role of Teckity in the success of your business success.
              </div>

              <div className="flex items-center space-x-[16px]">
                <div>
                  <Avatar
                    src="/kachi.png"
                    sx={{ width: {lg:"49px", sm:"30px"}, height: {lg:"49px", sm:"30px"} }}
                  />
                </div>
                <div>
                  <div className=" lg:text-left font-[500] text-[#0D6985] lg:text-[16px] text-[13px] ">
                    Justice Francis
                  </div>

                  <div className=" lg:text-left font-[500]  lg:text-[16px] text-[13px] ">
                    {dayjs("2023-11-13").fromNow()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        
          


         
          

          

         
          
         
        </div>
      </div>
    </>
  );
};

export default BlogScreen;
