import { Email, LinkedIn, Twitter } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

const FloatingIcons = () => {
  const [hovered, setHovered] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('white');

  const toggleBackgroundColor = () => {
    setBackgroundColor(prevColor => prevColor === 'white' ? 'lightblue' : 'white');
  }

  const [rightMargin, setRightMargin] = useState(window.innerWidth < 640 ? '1rem' : '2rem');

  useEffect(() => {
    const handleResize = () => {
      setRightMargin(window.innerWidth < 640 ? '1rem' : '2rem');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 

  const floatingIconsStyle = {
    position: 'fixed',
    bottom: '2rem',
    right: rightMargin,
    borderRadius: '50%',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center', // Center the icons
    zIndex:50
  };

  const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const iconStyle = {
    cursor: 'pointer',
  };

  const circleStyle = {
    borderRadius: '50%',
    backgroundColor: backgroundColor,
    padding: '0.5rem',
    marginRight:'0.2rem',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s', // Added transition for smooth effect
  };

  const emailHoverStyle = {
    color: '#D14836', // Gmail's red color
  };

  const twitterHoverStyle = {
    color: '#1DA1F2',
  };

  const linkedinHoverStyle = {
    color: '#0077B5',
  };

  return (
    <div style={floatingIconsStyle}>
      <div style={iconContainerStyle}>
        <div style={{ ...circleStyle, boxShadow: hovered === 'email' ? '0px 8px 12px rgba(0, 0, 0, 0.2)' : '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Email
            style={{
              ...iconStyle,
              ...(hovered === 'email' ? emailHoverStyle : undefined),
            }}
            onMouseEnter={() => setHovered('email')}
            onMouseLeave={() => setHovered(null)}
            onClick={() => {
                window.open("mailto:info@teckity.com", "_blank");
              }}
          />
        </div>
      </div>
      <div style={iconContainerStyle}>
        <div style={{ ...circleStyle, boxShadow: hovered === 'twitter' ? '0px 8px 12px rgba(0, 0, 0, 0.2)' : '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <Twitter
            style={{ ...iconStyle, color: hovered === 'twitter' ? '#1DA1F2' : undefined }}
            onMouseEnter={() => setHovered('twitter')}
            onMouseLeave={() => setHovered(null)}
            onClick={() => {
                window.open("https://x.com/teckity_?s=21&t=gZBPINvj83siYv0qkv0c8A", "_blank");
              }}
          />
        </div>
      </div>
      <div style={iconContainerStyle}>
        <div style={{ ...circleStyle, boxShadow: hovered === 'linkedin' ? '0px 8px 12px rgba(0, 0, 0, 0.2)' : '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <LinkedIn
            style={{ ...iconStyle, color: hovered === 'linkedin' ? '#0077B5' : undefined }}
            onMouseEnter={() => setHovered('linkedin')}
            onMouseLeave={() => setHovered(null)}
            onClick={() => {
                window.open("https://www.linkedin.com/company/teckity/", "_blank");
              }}
          />
        </div>
      </div>
    </div>
  );
};

export default FloatingIcons;
