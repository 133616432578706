import { Search } from "@mui/icons-material";
import { Button, Drawer, SwipeableDrawer } from "@mui/material";
import React from "react";

const MobileMenu = ({ open, setOpen }) => {
  return (
    <SwipeableDrawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
      <div className="">
        <div className="hover:bg-[#18BEF1] hover:text-white cursor-pointer px-12 py-4" onClick={()=> {
          window.location.pathname = '/'
          }}>
          Home
        </div>

        <div className="hover:bg-[#18BEF1] hover:text-white cursor-pointer px-12 py-4" onClick={()=> {window.location.pathname = '/contact-us'}}>
          Contact
        </div>
        <div className="hover:bg-[#18BEF1] hover:text-white cursor-pointer px-12 py-4" onClick={()=> {window.location.pathname = '/blog'}}>
          Blog
        </div>
        <div className="hover:bg-[#18BEF1] hover:text-white cursor-pointer px-12 py-4 mb-4" onClick={()=> {window.location.pathname = '/team'}}>
          Team
        </div>
       

        <div className="px-2">
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "32px",
              bgcolor: "#18bef1",
              color: "white",
              fontWeight: "semibold",
              fontSize: { xs: "16px", lg: "16px" },
              px: "32px",
              py: "16px",
              width: "100%",
              "&:hover": {
                color: "black",
              },
            }}
          >
            Contact Us
          </Button>
        </div>

        <div className="text-black  lg:px-32 px-4 lg:py-8  lg:pt-[60px]  text-[14px] lg:text-[16px] py-8  text-center ">
        Teckity 2023 © All Rights Reserved
      </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileMenu;
