import { Button, InputBase } from "@mui/material";
import React, { useState } from "react";
import Snack from "../components/Snack";

const ContactUsScreen = () => {
  const [open, setOpen] = useState();
  const [msg, setMsg] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    contact: "",
    email: "",
    about_org: "",
    project_budget: "",
    project_description: "",
    project_timeline: "",
    business_type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    fetch(
      "https://yelp-chatgpt-api-8d39b1455bcb.herokuapp.com/api/teckity/send-email",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOpen(true);
        setMsg("Message sent, you will get a response soon.");
        setFormData({
          first_name: "",
          last_name: "",
          contact: "",
          email: "",
          about_org: "",
          project_budget: "",
          project_description: "",
          project_timeline: "",
          business_type: "",
        });
        console.log("Success:", data);
      })
      .catch((error) => {
        setMsg("Error msg did not send")
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Snack open={open} setOpen={setOpen} msg={msg} />
      <div className="relative mb-[300px] lg:top-[150px] top-[93px] w-full lg:px-[113px] px-[19.5px]">
        <div className=" lg:text-center font-[700] lg:text-[49px] text-[31px] lg:mb-[44px] mb-[16px]">
          Get in Touch
        </div>
        <div className=" lg:text-center font-[300] lg:text-[25px] text-[16px] lg:mb-[44px] mb-[16px]">
          At Teckity, we don't just build products; we craft digital journeys.
          Let's embark on this journey together and transform your ideas into
          reality."
        </div>

        <div className="">
          <div className="    text-black ">
            <div className=" flex justify-center ">
              <div className="lg:grid grid-cols-5    w-full lg:bg-white bg-transparent  lg:shadow-lg  ">
                <div className="lg:col-span-2 flex flex-col  lg:space-y-8 space-y-[16px] lg:px-[30px] px-[16px]">
                  <div className="flex-1"></div>
                  <div className="text-[40px] font-[700]">
                    Let's Start a Conversation
                  </div>
                  <div className="text-[20px] font-[400]">
                    Reach out to us. We would like to hear from you.
                  </div>
                  <div className="flex-1"></div>
                </div>
                <div className="lg:col-span-3 bg-[#F1F1F5] lg:px-[20px] px-4 py-[30px] space-y-5 mt-[32px] lg:mt-0">
                  <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                    <InputBase
                      placeholder="First name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                    <InputBase
                      placeholder="Last name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                    <InputBase
                      placeholder="Contact"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                    <InputBase
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div>
                    <InputBase
                      placeholder="Tell us about your organization/ brand"
                      name="about_org"
                      value={formData.about_org}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div>
                    <InputBase
                      placeholder="Business Type"
                      name="business_type"
                      value={formData.business_type}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div>
                    <InputBase
                      placeholder="Project Description"
                      name="project_description"
                      value={formData.project_description}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div className="lg:flex justify-between lg:space-x-[20px] space-y-[16px] lg:space-y-0 ">
                    <InputBase
                      placeholder="Project Timeline"
                      name="project_timeline"
                      value={formData.project_timeline}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                    <InputBase
                      placeholder="Project Budget"
                      name="project_budget"
                      value={formData.project_budget}
                      onChange={handleChange}
                      sx={{
                        bgcolor: "white",
                        px: "16px",
                        py: "16px",
                        width: "100%",
                        fontSize: { lg: "20", sm: "13px" },
                        weight: "400",
                        fontStyle: "poppings",
                      }}
                    />
                  </div>

                  <div>
                    <Button
                      sx={{
                        textTransform: "none",
                        borderRadius: "32px",
                        bgcolor: "#18bef1",
                        color: "white",
                        fontWeight: "semibold",
                        fontSize: { xs: "16px", lg: "16px" },
                        px: "42px",
                        py: "10px",
                        width: "fit",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsScreen;
